import React, { useState } from 'react';
import PanelHeading from './panelHeading';
import { useForm, SubmitHandler } from "react-hook-form";
import Select from 'react-select';
import { useColorsSelector, useEditorDesignSelector, useFontsSelector, useTextSizesSelector } from '../../../store/selectors/editorSelector';
import { textSizes } from '../../../_config/interfaces/interfaces';
import { useDispatch } from 'react-redux';
import { ACTIONS } from '../../../_config';
import ColorPicker from './colorPicker';

const AddText: React.FC = () => {
    const dispatch = useDispatch();
    const item: any = useEditorDesignSelector();
    const sizes: textSizes = useTextSizesSelector();
    const colors: any[] = useColorsSelector();
    const fonts: any[] = useFontsSelector();
    const [tab, setTab] = useState('front');

    const updateDesign = (actionKey: string, payload: any) => {
        dispatch({ type: actionKey, payload: payload });
    };


    return (
        <div>
            <PanelHeading title='Add Text' />

            <div className='flex gap-1 border-b border-slate-900'>
                <button onClick={() => { setTab('front') }} className={`px-3 py-1 rounded-lg rounded-b-none text-sm font-semibold ${tab == 'front' ? 'bg-slate-900 text-slate-50' : 'bg-slate-100 text-slate-500'}`}>Front</button>
                <button onClick={() => { setTab('back') }} className={`px-3 py-1 rounded-lg rounded-b-none text-sm font-semibold ${tab == 'back' ? 'bg-slate-900 text-slate-50' : 'bg-slate-100 text-slate-500'}`}>Back</button>
            </div>

            {tab == 'front' && (
                <form className='py-3'>
                    <fieldset className='rounded-md border border-gray-500 px-2 pt-0 overflow-hidden mb-3'>
                        <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Front Text</legend>
                        <input type="text" value={item ? item.data.text.front.text : ''} className='w-full h-full focus:outline-none text-sm pb-2' onChange={(e: any) => {
                            updateDesign(ACTIONS.EDITOR.TEXT.FRONT.TEXT, { text: e.target.value })
                        }} />
                    </fieldset>

                    <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3'>
                        <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Text Size</legend>
                        <Select
                            classNamePrefix="inside-select"
                            className='w-full h-full focus:outline-none '
                            value={item ? item.data.text.front.size : null}
                            onChange={(e: any) => {
                                updateDesign(ACTIONS.EDITOR.TEXT.FRONT.SIZE, { size: e })
                            }}
                            options={sizes.front}
                        />
                    </fieldset>

                    <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3'>
                        <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Font</legend>
                        <Select
                            classNamePrefix="inside-select"
                            className='w-full h-full focus:outline-none '
                            value={item ? item.data.text.front.font : null}
                            onChange={(e: any) => {
                                updateDesign(ACTIONS.EDITOR.TEXT.FRONT.FONT, { font: e })
                            }}
                            options={fonts}
                        />
                    </fieldset>

                    <div className='flex items-center justify-between gap-3'>
                        <h3 className='text-sm'>Front Text Color</h3>
                        <ColorPicker color={item?.data.text?.front?.color} actionKey={ACTIONS.EDITOR.TEXT.FRONT.COLOR} payload="color" />
                    </div>
                    <div className='flex items-center justify-between gap-3'>
                        <h3 className='text-sm'>Front Text Outline</h3>
                        <div className='flex gap-3 items-center'>

                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3 max-w-[60px]'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Thikness</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.text.front?.outline} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.TEXT.FRONT.OUTLINE, { outline: e.target.value });
                                }} />
                            </fieldset>

                            <ColorPicker color={item?.data.text.front?.outlineColor} actionKey={ACTIONS.EDITOR.TEXT.FRONT.OUTLINE_COLOR} payload="color" />
                        </div>

                    </div>
                    <div className='flex items-center justify-between gap-3'>
                        <h3 className='text-sm'>Front Text Outline Over</h3>
                        <div className='flex gap-3 items-center'>

                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3 max-w-[60px]'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Thikness</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.text.front?.outlineOver} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.TEXT.FRONT.OUTLINE_OVER, { outline: e.target.value });
                                }} />
                            </fieldset>

                            <ColorPicker color={item?.data.text.front?.outlineOverColor} actionKey={ACTIONS.EDITOR.TEXT.FRONT.OUTLINE_OVER_COLOR} payload="color" />
                        </div>

                    </div>
                    <div className='flex items-center justify-between gap-3'>

                        <h3 className='text-sm'>Front Text Shadow</h3>

                        <div className='flex gap-3 items-center'>

                            <label className="inline-flex items-center justify-between cursor-pointer w-full">
                                <input type="checkbox" value="" className="sr-only peer" checked={item?.data.text.front?.shadow} onChange={() => {
                                    updateDesign(ACTIONS.EDITOR.TEXT.FRONT.SHADOW, { shadow: !item?.data.text.front?.shadow });
                                }} />
                                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </label>

                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 max-w-[60px] mb-3'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Top</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.text.front?.shadowTop} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.TEXT.FRONT.SHADOW_TOP, { top: e.target.value });
                                }} />
                            </fieldset>
                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 max-w-[60px] mb-3'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Left</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.text.front?.shadowLeft} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.TEXT.FRONT.SHADOW_LEFT, { left: e.target.value });
                                }} />
                            </fieldset>

                            <ColorPicker color={item?.data.text.front?.shadowColor} actionKey={ACTIONS.EDITOR.TEXT.FRONT.SHADOW_COLOR} payload="color" />



                        </div>



                    </div>

                </form>
            )}
            {tab == 'back' && (
                <form className='py-3'>
                    <fieldset className='rounded-md border border-gray-500 px-2 pt-0 overflow-hidden mb-3'>
                        <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Back Text</legend>
                        <input type="text" value={item ? item.data.text.back.text : ''} className='w-full h-full focus:outline-none text-sm pb-2' onChange={(e: any) => {
                            updateDesign(ACTIONS.EDITOR.TEXT.BACK.TEXT, { text: e.target.value })
                        }} />
                    </fieldset>

                    <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3'>
                        <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Text Size</legend>
                        <Select
                            classNamePrefix="inside-select"
                            className='w-full h-full focus:outline-none '
                            value={item ? item.data.text.back.size : null}
                            onChange={(e: any) => {
                                updateDesign(ACTIONS.EDITOR.TEXT.BACK.SIZE, { size: e })
                            }}
                            options={sizes.back}
                        />
                    </fieldset>

                    <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3'>
                        <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Back Text Font</legend>
                        <Select
                            classNamePrefix="inside-select"
                            className='w-full h-full focus:outline-none '
                            value={item ? item.data.text.back.font : null}
                            onChange={(e: any) => {
                                updateDesign(ACTIONS.EDITOR.TEXT.BACK.FONT, { font: e })
                            }}
                            options={fonts}
                        />
                    </fieldset>

                    <div className='flex items-center justify-between gap-3'>
                        <h3 className='text-sm'>Back Text Color</h3>
                        <ColorPicker color={item?.data.text?.back?.color} actionKey={ACTIONS.EDITOR.TEXT.BACK.COLOR} payload="color" />
                    </div>
                    <div className='flex items-center justify-between gap-3'>
                        <h3 className='text-sm'>Back Text Outline</h3>
                        <div className='flex gap-3 items-center'>

                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3 max-w-[60px]'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Thikness</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.text.back?.outline} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.TEXT.BACK.OUTLINE, { outline: e.target.value });
                                }} />
                            </fieldset>

                            <ColorPicker color={item?.data.text.back?.outlineColor} actionKey={ACTIONS.EDITOR.TEXT.BACK.OUTLINE_COLOR} payload="color" />
                        </div>

                    </div>
                    <div className='flex items-center justify-between gap-3'>
                        <h3 className='text-sm'>Back Text Outline Over</h3>
                        <div className='flex gap-3 items-center'>

                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3 max-w-[60px]'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Thikness</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.text.back?.outlineOver} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.TEXT.BACK.OUTLINE_OVER, { outline: e.target.value });
                                }} />
                            </fieldset>

                            <ColorPicker color={item?.data.text.back?.outlineOverColor} actionKey={ACTIONS.EDITOR.TEXT.BACK.OUTLINE_OVER_COLOR} payload="color" />
                        </div>

                    </div>
                    <div className='flex items-center justify-between gap-3'>

                        <h3 className='text-sm'>Back Text Shadow</h3>

                        <div className='flex gap-3 items-center'>

                            <label className="inline-flex items-center justify-between cursor-pointer w-full">
                                <input type="checkbox" value="" className="sr-only peer" checked={item?.data.text.back?.shadow} onChange={() => {
                                    updateDesign(ACTIONS.EDITOR.TEXT.BACK.SHADOW, { shadow: !item?.data.text.back?.shadow });
                                }} />
                                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </label>

                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 max-w-[60px] mb-3'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Top</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.text.back?.shadowTop} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.TEXT.BACK.SHADOW_TOP, { top: e.target.value });
                                }} />
                            </fieldset>
                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 max-w-[60px] mb-3'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Left</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.text.back?.shadowLeft} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.TEXT.BACK.SHADOW_LEFT, { left: e.target.value });
                                }} />
                            </fieldset>

                            <ColorPicker color={item?.data.text.back?.shadowColor} actionKey={ACTIONS.EDITOR.TEXT.BACK.SHADOW_COLOR} payload="color" />



                        </div>



                    </div>

                </form>
            )}

        </div>
    );
};

export default AddText;