import React from 'react';
import PanelHeading from './panelHeading';
import Icon from '../../../components/icons';

const ReadyToGo: React.FC = () => {
    return (
        <div>
            <PanelHeading title='Ready To Go'/>
            
            <form className='py-3'>
                    <fieldset className='rounded-md border border-gray-500 px-2 pt-0 overflow-hidden mb-3'>
                        <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Name</legend>
                        <input type="text" className='w-full h-full focus:outline-none text-sm pb-2'  />
                    </fieldset>
                    <fieldset className='rounded-md border border-gray-500 px-2 pt-0 overflow-hidden mb-3'>
                        <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Email</legend>
                        <input type="email" className='w-full h-full focus:outline-none text-sm pb-2'  />
                    </fieldset>
                    <fieldset className='rounded-md border border-gray-500 px-2 pt-0 overflow-hidden mb-3'>
                        <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Address</legend>
                        <textarea className='focus:outline-none text-sm w-full min-h-[60px] text-slate-700' spellCheck={false}></textarea>
                    </fieldset>

                    

                    
                    <div className='flex justify-end gap-2'>
                        
                        <button className='flex gap-2 items-center bg-primary px-3 py-2 rounded-md text-gray-50'>
                            <Icon name='download' size={18}/>
                            <span className='text-md font-medium'>Download</span>
                        </button>

                        <button className='flex gap-2 items-center bg-primary px-3 py-2 rounded-md text-gray-50'>
                            <Icon name='save' size={18}/>
                            <span className='text-md font-medium'>Save</span>
                        </button>

                        
                    </div>
                    

                </form>


        </div>
    );
};

export default ReadyToGo;