import React, { useReducer } from 'react';
import Header from '../../components/header';
import Editor from './editor';
import DesignView from './designView';


const Design: React.FC = () => {

    

    return (
        <div className='h-screen flex flex-col'>
            <Header />
            <div className='flex flex-grow'>
                <Editor  />
                <DesignView />
            </div>
        </div>
    );
};

export default Design;