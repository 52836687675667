import { Outlet, Navigate } from 'react-router-dom';
import { useAuthStatus } from '../store/selectors/userSelector';
import { URLS } from '.';

const PublicRoutes = () => {
    const isAuth = useAuthStatus();
    return (
        isAuth ? <Navigate to={URLS.HOME} />: <Outlet /> 
    )
}

export default PublicRoutes;