import { combineReducers } from 'redux';
import { ACTIONS } from '../../_config';

import loaderReducer from './loaderReducer';
import userReducer from './userReducer';
import editorReducer from './editorReducer';

const appReducer = combineReducers({
	loader: loaderReducer,
	user: userReducer,
	editor: editorReducer,
})

const rootReducer = (state: any, action: any) => {
	if (action.type === ACTIONS.RESET_REDUCER) {
		state = undefined;
		localStorage.removeItem('persist:root');
	}

	return appReducer(state, action)
	
}

export default rootReducer