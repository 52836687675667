import React from 'react';
import Icon from '../../components/icons';
import SidebarMenuButton from './components/sidebarMenuButton';
import { ACTIONS, KEYS } from '../../_config';
import { useEditorPanelSelector } from '../../store/selectors/editorSelector';
import { useDispatch } from 'react-redux';

interface SidebarProps {
    
}

const Sidebar: React.FC<SidebarProps> = ({}) => {

    const dispach = useDispatch();
    const panel = useEditorPanelSelector();

    return (
        <div className='w-[48px] flex-grow-0 flex-shrink-0 bg-gray-200'>
            <div className='w-full'>
                <SidebarMenuButton icon='jersey' onClick={() => dispach({type:ACTIONS.EDITOR.CHANGE_EDIT_PANEL, payload:{editorPanel:KEYS.EDIT_PANELS.PICK_DESIGN}})} active={panel == KEYS.EDIT_PANELS.PICK_DESIGN} />
                <SidebarMenuButton icon='brush' onClick={() => dispach({type:ACTIONS.EDITOR.CHANGE_EDIT_PANEL, payload:{editorPanel:KEYS.EDIT_PANELS.CHANGE_COLOR}})} active={panel == KEYS.EDIT_PANELS.CHANGE_COLOR} />
                <SidebarMenuButton icon='text' onClick={() => dispach({type:ACTIONS.EDITOR.CHANGE_EDIT_PANEL, payload:{editorPanel:KEYS.EDIT_PANELS.ADD_TEXT}})} active={panel == KEYS.EDIT_PANELS.ADD_TEXT} />
                <SidebarMenuButton icon='number' onClick={() => dispach({type:ACTIONS.EDITOR.CHANGE_EDIT_PANEL, payload:{editorPanel:KEYS.EDIT_PANELS.JERSEY_NUMBER}})} active={panel == KEYS.EDIT_PANELS.JERSEY_NUMBER} />
                <SidebarMenuButton icon='image' onClick={() => dispach({type:ACTIONS.EDITOR.CHANGE_EDIT_PANEL, payload:{editorPanel:KEYS.EDIT_PANELS.ADD_IMAGE}})} active={panel == KEYS.EDIT_PANELS.ADD_IMAGE} />
                <SidebarMenuButton icon='rocket' onClick={() => dispach({type:ACTIONS.EDITOR.CHANGE_EDIT_PANEL, payload:{editorPanel:KEYS.EDIT_PANELS.READY_TO_GO}})} active={panel == KEYS.EDIT_PANELS.READY_TO_GO} />
            </div>
        </div>
    );
};

export default Sidebar;