import React, { PureComponent } from 'react';

interface PanelHeadingProps {
    title: string;
}

class PanelHeading extends PureComponent<PanelHeadingProps> {
    render() {
        const { title } = this.props;
        return (<h2 className='text-2xl font-semibold mb-2'>{title}</h2>);
    }
}

export default PanelHeading;