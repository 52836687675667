import { ACTIONS, KEYS } from '../../_config'
import { ActionExtended } from '../../_config/interfaces/interfaces';

export interface UserReducer {
	user: any;
	accessToken: string | null;
	refreshToken: string | null;
	isAuthenticated: boolean;
	permissions: any;
}

const initialState: UserReducer = {
	user: null,
	accessToken: null,
	refreshToken: null,
	isAuthenticated: false,
	permissions: null,
};

const userReducer = (state = initialState, action: ActionExtended) => {
	switch (action.type) {
		case ACTIONS.USER.LOGIN:
			return {
				...state,
				user: action.payload.user,
				accessToken: action.payload.acceaaToken || localStorage.getItem(KEYS.AUTH_ACCESS),
				refreshToken: action.payload.refreshToken || localStorage.getItem(KEYS.AUTH_REFRESH),
				isAuthenticated: true,
				permissions: action.payload.permissions
			};

		case ACTIONS.USER.USER:
			return {
				...state,
				user: action.payload,

			};

		case ACTIONS.USER.LOGOUT:
			return {
				...state,
				user: null,
				accessToken: null,
				isAuthenticated: false,
				permissions: null,
			};
		default:
			return state;
	}
};

export default userReducer;
