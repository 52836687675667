import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import PublicRoutes from './_config/publicRoutes';
import { URLS } from './_config';
import Login from './pages/login';
import PrivateRoutes from './_config/privateRoutes';
import Home from './pages/home';
import Page404 from './pages/404';
import Design from './pages/design';

function App() {
	return (
		<Router>
			
			<div className="App">
				<Routes>
					<Route element={<PublicRoutes />}>
						<Route path={URLS.LOGIN} element={<Login />}></Route>
						{/* <Route path={URLS.FORGOT_PASSWORD} element={<ForgotPassword />}></Route> */}
					</Route>

					{/* <Route path={URLS.RESET_PASSWORD} element={<ResetPassword />}></Route> */}

					<Route element={<PrivateRoutes />}>
						{/* <Route path={URLS.LOGOUT} element={<Logout />}></Route> */}
						<Route path={URLS.HOME} element={<Home />}></Route>
						<Route path={URLS.DESIGN} element={<Design />}></Route>
					</Route>

					<Route path={`*`} element={<Page404 />}></Route>
				</Routes>
			</div>
		</Router>
	);
}

export default App;
