import { takeLatest, all, takeEvery } from 'redux-saga/effects'; // fork, take,  takeEvery, takeLeading
import { SAGA_ACTIONS } from '../../_config';

import * as userSaga from './userSaga';


export function* sagas() {
	yield all([
		/* takeEvery(SAGA_ACTIONS.USER.LOGIN, userSaga.login),
		takeEvery(SAGA_ACTIONS.USER.LOGOUT, userSaga.logout), */




	]);
}