import React, { useState } from 'react';
import { useColorsSelector } from '../../../store/selectors/editorSelector';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { useDispatch } from 'react-redux';
interface ColorPickerProps {
    color: any
    actionKey: string;
    payload: any;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color, actionKey, payload }) => {

    const colors: any[] = useColorsSelector();
    const dispatch = useDispatch();


    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white text-sm font-semibold text-gray-900 shadow-sm  hover:bg-gray-50">
                    <div className='w-6 h-6 rounded-md shadow-gray-900 shadow-sm cursor-pointer' style={{ backgroundColor: color }} ></div>
                </MenuButton>
            </div>

            <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
                <div className='flex gap-3 flex-wrap mt-1 p-2'>
                    {colors && colors.map((color) => (
                        <div key={color.name} className=''>
                            <div className='w-6 h-6 rounded-md shadow-gray-900 shadow-sm cursor-pointer' style={{ backgroundColor: color.hex }} onClick={() => {
                                dispatch({ type: actionKey, payload: { [payload]: color.hex } });
                            }}></div>
                        </div>
                    ))}
                </div>
            </MenuItems>
        </Menu>
    );
};

export default ColorPicker;