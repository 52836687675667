import React, { useEffect } from 'react';
import { useEditorDesignSelector } from '../../store/selectors/editorSelector';
import parse from 'html-react-parser';
import Jersey1 from '../../products/jerseys/jersey1';
const DesignView: React.FC = () => {
    const item:any = useEditorDesignSelector(); 


    return (
        <div className='p-2 flex-grow bg-gray-100 flex items-center justify-center'>
            {item == null && <p className='text-center text-gray-500'>No Design Selected</p>}

            {item?.name == 'Jersey 1' && <Jersey1 data={item.data}/>}

        </div>
    );
};

export default DesignView;