export const KEYS = {
    AUTH_ACCESS: "osm-auth-access",
    AUTH_REFRESH: "osm-auth-refresh",

    EDIT_PANELS: {
        PICK_DESIGN: 'pick-design',
        CHANGE_COLOR: 'change-color',
        ADD_TEXT: 'add-text',
        JERSEY_NUMBER: 'jersey-number',
        ADD_IMAGE: 'add-image',
        READY_TO_GO: 'ready-to-go',
    }
}

export const URLS = {
    LOGIN:'/login',
    FORGOT_PASSWORD:'/forgot-password',
    RESET_PASSWORD:'/reset-password',
    HOME:'/',
    DESIGN:'/design',
}

export const ACTIONS = {
    RESET_REDUCER: "ACTIONS/RESET_REDUCER",

    LOADER: {
        FP_STATUS: "ACTIONS/LOADER/FP_STATUS",
    },

    USER: {
        LOGIN: "ACTIONS/USER/LOGIN",
        USER: "ACTIONS/USER/USER",
        LOGOUT: "ACTIONS/USER/LOGOUT",
        FORGET_PASSWORD: "ACTIONS/USER/FORGET_PASSWORD",
        RESET_PASSWORD: "ACTIONS/USER/RESET_PASSWORD",
        PASSWORD_RESET: "ACTIONS/USER/PASSWORD_RESET",
    },
    EDITOR:{
        CHANGE_EDIT_PANEL: "ACTIONS/EDITOR/CHANGE_EDIT_PANEL",
        UPDATE_DESIGN: "ACTIONS/EDITOR/UPDATE_DESIGN",
        COLORS: "ACTIONS/EDITOR/COLORS",
        JERSEY_NUMBER: {
            NUMBER: "ACTIONS/EDITOR/JERSEY_NUMBER/NUMBER",
            FONT: "ACTIONS/EDITOR/JERSEY_NUMBER/FONT",
            FRONT: {
                COLOR: "ACTIONS/EDITOR/JERSEY_NUMBER/FRONT/COLOR",
                OUTLINE_COLOR: "ACTIONS/EDITOR/JERSEY_NUMBER/FRONT/OUTLINE_COLOR",
                OUTLINE: "ACTIONS/EDITOR/JERSEY_NUMBER/FRONT/OUTLINE",
                OUTLINE_OVER_COLOR: "ACTIONS/EDITOR/JERSEY_NUMBER/FRONT/OUTLINE_OVER_COLOR",
                OUTLINE_OVER: "ACTIONS/EDITOR/JERSEY_NUMBER/FRONT/OUTLINE_OVER",
                SHADOW: "ACTIONS/EDITOR/JERSEY_NUMBER/FRONT/SHADOW",
                SHADOW_COLOR: "ACTIONS/EDITOR/JERSEY_NUMBER/FRONT/SHADOW_COLOR",
                SHADOW_TOP: "ACTIONS/EDITOR/JERSEY_NUMBER/FRONT/SHADOW_TOP",
                SHADOW_LEFT: "ACTIONS/EDITOR/JERSEY_NUMBER/FRONT/SHADOW_LEFT",
                SIZE: "ACTIONS/EDITOR/JERSEY_NUMBER/FRONT/SIZE",
            },
            BACK: {
                COLOR: "ACTIONS/EDITOR/JERSEY_NUMBER/BACK/COLOR",
                OUTLINE_COLOR: "ACTIONS/EDITOR/JERSEY_NUMBER/BACK/OUTLINE_COLOR",
                OUTLINE: "ACTIONS/EDITOR/JERSEY_NUMBER/BACK/OUTLINE",
                OUTLINE_OVER_COLOR: "ACTIONS/EDITOR/JERSEY_NUMBER/BACK/OUTLINE_OVER_COLOR",
                OUTLINE_OVER: "ACTIONS/EDITOR/JERSEY_NUMBER/BACK/OUTLINE_OVER",
                SHADOW: "ACTIONS/EDITOR/JERSEY_NUMBER/BACK/SHADOW",
                SHADOW_COLOR: "ACTIONS/EDITOR/JERSEY_NUMBER/BACK/SHADOW_COLOR",
                SHADOW_TOP: "ACTIONS/EDITOR/JERSEY_NUMBER/BACK/SHADOW_TOP",
                SHADOW_LEFT: "ACTIONS/EDITOR/JERSEY_NUMBER/BACK/SHADOW_LEFT",
                SIZE: "ACTIONS/EDITOR/JERSEY_NUMBER/BACK/SIZE",
            },
            SLEEVES: {
                COLOR: "ACTIONS/EDITOR/JERSEY_NUMBER/SLEEVES/COLOR",
                OUTLINE_COLOR: "ACTIONS/EDITOR/JERSEY_NUMBER/SLEEVES/OUTLINE_COLOR",
                OUTLINE: "ACTIONS/EDITOR/JERSEY_NUMBER/SLEEVES/OUTLINE",
                OUTLINE_OVER_COLOR: "ACTIONS/EDITOR/JERSEY_NUMBER/SLEEVES/OUTLINE_OVER_COLOR",
                OUTLINE_OVER: "ACTIONS/EDITOR/JERSEY_NUMBER/SLEEVES/OUTLINE_OVER",
                SHADOW: "ACTIONS/EDITOR/JERSEY_NUMBER/SLEEVES/SHADOW",
                SHADOW_COLOR: "ACTIONS/EDITOR/JERSEY_NUMBER/SLEEVES/SHADOW_COLOR",
                SHADOW_TOP: "ACTIONS/EDITOR/JERSEY_NUMBER/SLEEVES/SHADOW_TOP",
                SHADOW_LEFT: "ACTIONS/EDITOR/JERSEY_NUMBER/SLEEVES/SHADOW_LEFT",
                SIZE: "ACTIONS/EDITOR/JERSEY_NUMBER/SLEEVES/SIZE",
            },
            

        },
        TEXT:{
            FRONT:{
                TEXT: "ACTIONS/EDITOR/TEXT/FRONT/TEXT",
                FONT: "ACTIONS/EDITOR/TEXT/FRONT/FONT",
                COLOR: "ACTIONS/EDITOR/TEXT/FRONT/COLOR",
                OUTLINE_COLOR: "ACTIONS/EDITOR/TEXT/FRONT/OUTLINE_COLOR",
                OUTLINE: "ACTIONS/EDITOR/TEXT/FRONT/OUTLINE",
                OUTLINE_OVER_COLOR: "ACTIONS/EDITOR/TEXT/FRONT/OUTLINE_OVER_COLOR",
                OUTLINE_OVER: "ACTIONS/EDITOR/TEXT/FRONT/OUTLINE_OVER",
                SHADOW: "ACTIONS/EDITOR/TEXT/FRONT/SHADOW",
                SHADOW_COLOR: "ACTIONS/EDITOR/TEXT/FRONT/SHADOW_COLOR",
                SHADOW_TOP: "ACTIONS/EDITOR/TEXT/FRONT/SHADOW_TOP",
                SHADOW_LEFT: "ACTIONS/EDITOR/TEXT/FRONT/SHADOW_LEFT",
                SIZE: "ACTIONS/EDITOR/TEXT/FRONT/SIZE",
            },
            BACK:{
                TEXT: "ACTIONS/EDITOR/TEXT/BACK/TEXT",
                FONT: "ACTIONS/EDITOR/TEXT/BACK/FONT",
                COLOR: "ACTIONS/EDITOR/TEXT/BACK/COLOR",
                OUTLINE_COLOR: "ACTIONS/EDITOR/TEXT/BACK/OUTLINE_COLOR",
                OUTLINE: "ACTIONS/EDITOR/TEXT/BACK/OUTLINE",
                OUTLINE_OVER_COLOR: "ACTIONS/EDITOR/TEXT/BACK/OUTLINE_OVER_COLOR",
                OUTLINE_OVER: "ACTIONS/EDITOR/TEXT/BACK/OUTLINE_OVER",
                SHADOW: "ACTIONS/EDITOR/TEXT/BACK/SHADOW",
                SHADOW_COLOR: "ACTIONS/EDITOR/TEXT/BACK/SHADOW_COLOR",
                SHADOW_TOP: "ACTIONS/EDITOR/TEXT/BACK/SHADOW_TOP",
                SHADOW_LEFT: "ACTIONS/EDITOR/TEXT/BACK/SHADOW_LEFT",
                SIZE: "ACTIONS/EDITOR/TEXT/BACK/SIZE",
            },
        }
    }
}

export const SAGA_ACTIONS = {
    USER: {
        LOGIN: "SAGA_ACTIONS/USER/LOGIN",
        LOGOUT: "SAGA_ACTIONS/USER/LOGOUT",
        FORGET_PASSWORD: "SAGA_ACTIONS/USER/FORGET_PASSWORD",
        RESET_PASSWORD: "SAGA_ACTIONS/USER/RESET_PASSWORD",
    }
}