import { ACTIONS } from '../../_config'
import { ActionExtended } from '../../_config/interfaces/interfaces';

export interface LoaderReducer {
  fpLoader: boolean;
}

const initialState: LoaderReducer = {
  fpLoader: false
};

const loaderReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.LOADER.FP_STATUS:
      return {
        ...state,
        fpLoader: action.payload,
      };
    default:
      return state;
  }
};

export default loaderReducer;
