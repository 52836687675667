import React from 'react';
import Icon, { iconNames } from '../../../components/icons';

interface SidebarMenuButtonProps {
    icon: iconNames;
    onClick: () => void;
    active?: boolean;
}

const SidebarMenuButton: React.FC<SidebarMenuButtonProps> = ({ icon, onClick, active }) => {
    return (
        <button onClick={onClick} className={`w-full h-[48px] flex items-center justify-center cursor-pointer ${active?'bg-red-700 text-gray-50':'text-gray-600'}`}>
             <Icon name={icon} size={24} />
        </button>
    );
};

export default SidebarMenuButton;