import React, { useState } from 'react';
import PanelHeading from './panelHeading';

const AddImage: React.FC = () => {
    const [tab, setTab] = useState('front');

    return (
        <div>
            <PanelHeading title='Add Image'/>
            <div className='flex gap-1 border-b border-slate-900'>
                <button onClick={()=>{setTab('front')}} className={`px-3 py-1 rounded-lg rounded-b-none text-sm font-semibold ${tab == 'front'?'bg-slate-900 text-slate-50':'bg-slate-100 text-slate-500'}`}>Front</button>
                <button onClick={()=>{setTab('back')}} className={`px-3 py-1 rounded-lg rounded-b-none text-sm font-semibold ${tab == 'back'?'bg-slate-900 text-slate-50':'bg-slate-100 text-slate-500'}`}>Back</button>
            </div>

            {tab == 'front' && (
                <form className='py-3'>
                    <input type="file" accept='.jpg,.png' className="block w-full text-xs text-slate-900 border border-slate-900 rounded-md cursor-pointer bg-gray-50  focus:outline-none placeholder-gray-400"/>

                </form>
            )}
            {tab == 'back' && (
                <form className='py-3'>
                    <input type="file" accept='.jpg,.png' className="block w-full text-xs text-slate-900 border border-slate-900 rounded-md cursor-pointer bg-gray-50  focus:outline-none placeholder-gray-400"/>

                </form>
            )}
        </div>
    );
};

export default AddImage;