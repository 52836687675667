import { useSelector } from 'react-redux'
import { StateExtended } from '../../_config/interfaces/stateExtended'


export function useEditorPanelSelector() {
  const panel = useSelector((state: StateExtended) => state.editor.editorPanel)
  return panel
}

export function useEditorDesignSelector() {
  const design = useSelector((state: StateExtended) => state.editor.design)
  return design
}
export function useColorsSelector() {
  const colors = useSelector((state: StateExtended) => state.editor.colors)
  return colors
}

export function useTextSizesSelector() {
  const textSizes = useSelector((state: StateExtended) => state.editor.textSizes)
  return textSizes
}
export function useNumberSizesSelector() {
  const numberSizes = useSelector((state: StateExtended) => state.editor.numberSizes)
  return numberSizes
}
export function useTextPositionsSelector() {
  const textPositions = useSelector((state: StateExtended) => state.editor.textPositions)
  return textPositions
}
export function useNumberPositionsSelector() {
  const numberPositions = useSelector((state: StateExtended) => state.editor.numberPositions)
  return numberPositions
}
export function useFontsSelector() {
  const fonts = useSelector((state: StateExtended) => state.editor.fonts)
  return fonts
}