import React, { useEffect, useState } from 'react';
import PanelHeading from './panelHeading';

import Select from 'react-select';
import { useColorsSelector, useEditorDesignSelector, useFontsSelector, useNumberPositionsSelector, useNumberSizesSelector } from '../../../store/selectors/editorSelector';
import { numberSizes, textSizes } from '../../../_config/interfaces/interfaces';
import { useDispatch } from 'react-redux';
import { ACTIONS } from '../../../_config';
import ColorPicker from './colorPicker';

interface AddJerseyNumberProps {
    onAdd?: (number: number) => void;
}

const AddJerseyNumber: React.FC<AddJerseyNumberProps> = ({ onAdd }) => {
    const dispatch = useDispatch();
    const item: any = useEditorDesignSelector();
    const sizes: numberSizes = useNumberSizesSelector();
    const fonts: any[] = useFontsSelector();
    const positions: textSizes = useNumberPositionsSelector();

    const [tab, setTab] = useState('front');
    const [sleeves, setSleeves] = useState<boolean>(false);


    const updateDesign = (actionKey: string, payload: any) => {
        dispatch({ type: actionKey, payload: payload });
    };

    const updateNumber = (e: any) => {
        dispatch({ type: ACTIONS.EDITOR.UPDATE_DESIGN, payload: { design: { ...item, ...{ data: { ...item.data, number: { ...item.data.number, number: e.target.value } } } } } });
    };

    useEffect(() => {
        if (item?.data.number.font == null) {
            updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.FONT, { font: fonts[0] });
        }
        if (item?.data.number.front.size == null) {
            updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.SIZE, { size: sizes.front[0] });
        }
        if (item?.data.number.back.size == null) {
            updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.BACK.SIZE, { size: sizes.back[0] });
        }
        if (item?.data.number.sleeves.size == null) {
            updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.SIZE, { size: sizes.sleeves[0] });
        }
    }, [item?.id]);

    return (
        <div>
            <PanelHeading title='Add Number' />

            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 overflow-hidden mb-3'>
                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Number</legend>
                <input type="text" value={item ? item.data.number.number : ''} className='w-full h-full focus:outline-none text-sm pb-2' onChange={updateNumber} />
            </fieldset>

            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3'>
                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Font</legend>
                <Select
                    classNamePrefix="inside-select"
                    className='w-full h-full focus:outline-none '
                    value={item?.data.number.font}
                    onChange={(value) => {
                        updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.FONT, { font: value });
                    }}
                    options={fonts}
                />
            </fieldset>

            <label className="inline-flex items-center justify-between cursor-pointer w-full mb-3">
                <input type="checkbox" value="" className="sr-only peer" checked={sleeves} onChange={() => { setSleeves(!sleeves); if (tab == 'sleeves') { setTab('front') } }} />
                <span className="text-sm font-medium text-gray-900 dark:text-gray-300">Sleeves</span>
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

            </label>


            <div className='flex gap-1 border-b border-slate-900'>
                <button onClick={() => { setTab('front') }} className={`px-3 py-1 rounded-lg rounded-b-none text-sm font-semibold ${tab == 'front' ? 'bg-slate-900 text-slate-50' : 'bg-slate-100 text-slate-500'}`}>Front</button>
                <button onClick={() => { setTab('back') }} className={`px-3 py-1 rounded-lg rounded-b-none text-sm font-semibold ${tab == 'back' ? 'bg-slate-900 text-slate-50' : 'bg-slate-100 text-slate-500'}`}>Back</button>
                {sleeves && <button onClick={() => { setTab('sleeves') }} className={`px-3 py-1 rounded-lg rounded-b-none text-sm font-semibold ${tab == 'sleeves' ? 'bg-slate-900 text-slate-50' : 'bg-slate-100 text-slate-500'}`}>Sleeves</button>}
            </div>

            {tab == 'front' && (
                <form className='py-3'>

                    <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3'>
                        <legend className='px-2  top-0 text-xs mt-[-10px] italic'>front Number Size</legend>
                        <Select
                            classNamePrefix="inside-select"
                            className='w-full h-full focus:outline-none '
                            value={item?.data.number?.front?.size}
                            onChange={(value) => {
                                updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.SIZE, { size: value });
                            }}
                            options={sizes.front}
                        />
                    </fieldset>

                    <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3'>
                        <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Front Number Position</legend>
                        <Select
                            classNamePrefix="inside-select"
                            className='w-full h-full focus:outline-none '
                            //value={selectedDesign}
                            //onChange={setSelectedDesign}
                            options={positions.front}
                        />
                    </fieldset>


                    <div className='flex items-center justify-between gap-3'>
                        <h3 className='text-sm'>Front Number Color</h3>
                        <ColorPicker color={item?.data.number?.front?.color} actionKey={ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.COLOR} payload="color" />
                    </div>
                    <div className='flex items-center justify-between gap-3'>
                        <h3 className='text-sm'>Front Number Outline</h3>
                        <div className='flex gap-3 items-center'>

                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3 max-w-[60px]'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Thikness</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.number.front?.outline} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.OUTLINE, { outline: e.target.value });
                                }} />
                            </fieldset>

                            <ColorPicker color={item?.data.number.front?.outlineColor} actionKey={ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.OUTLINE_COLOR} payload="color" />
                        </div>

                    </div>
                    <div className='flex items-center justify-between gap-3'>
                        <h3 className='text-sm'>Front Number Outline Over</h3>
                        <div className='flex gap-3 items-center'>

                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3 max-w-[60px]'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Thikness</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.number.front?.outlineOver} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.OUTLINE_OVER, { outline: e.target.value });
                                }} />
                            </fieldset>

                            <ColorPicker color={item?.data.number.front?.outlineOverColor} actionKey={ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.OUTLINE_OVER_COLOR} payload="color" />
                        </div>

                    </div>
                    <div className='flex items-center justify-between gap-3'>

                        <h3 className='text-sm'>Front Number Shadow</h3>

                        <div className='flex gap-3 items-center'>

                            <label className="inline-flex items-center justify-between cursor-pointer w-full">
                                <input type="checkbox" value="" className="sr-only peer" checked={item?.data.number.front?.shadow} onChange={() => {
                                    updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.SHADOW, { shadow: !item?.data.number.front?.shadow });
                                }} />
                                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </label>

                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 max-w-[60px] mb-3'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Top</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.number.front?.shadowTop} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.SHADOW_TOP, { top: e.target.value });
                                }} />
                            </fieldset>
                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 max-w-[60px] mb-3'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Left</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.number.front?.shadowLeft} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.SHADOW_LEFT, { left: e.target.value });
                                }} />
                            </fieldset>

                            <ColorPicker color={item?.data.number.front?.shadowColor} actionKey={ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.SHADOW_COLOR} payload="color" />



                        </div>



                    </div>

                </form>
            )}
            {tab == 'back' && (
                <form className='py-3'>


                    <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3'>
                        <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Back Number Size</legend>
                        <Select
                            classNamePrefix="inside-select"
                            className='w-full h-full focus:outline-none '
                            value={item?.data.number?.back?.size}
                            onChange={(value) => {
                                updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.BACK.SIZE, { size: value });
                            }}
                            options={sizes.back}
                        />
                    </fieldset>

                    <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3'>
                        <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Back Number Position</legend>
                        <Select
                            classNamePrefix="inside-select"
                            className='w-full h-full focus:outline-none '
                            //value={selectedDesign}
                            //onChange={setSelectedDesign}
                            options={positions.back}
                        />
                    </fieldset>

                    <div className='flex items-center justify-between gap-3'>
                        <h3 className='text-sm'>Back Number Color</h3>
                        <ColorPicker color={item?.data.number?.back?.color} actionKey={ACTIONS.EDITOR.JERSEY_NUMBER.BACK.COLOR} payload="color" />
                    </div>
                    <div className='flex items-center justify-between gap-3'>
                        <h3 className='text-sm'>Back Number Outline</h3>
                        <div className='flex gap-3 items-center'>

                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3 max-w-[60px]'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Thikness</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.number.back?.outline} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.BACK.OUTLINE, { outline: e.target.value });
                                }} />
                            </fieldset>

                            <ColorPicker color={item?.data.number.back?.outlineColor} actionKey={ACTIONS.EDITOR.JERSEY_NUMBER.BACK.OUTLINE_COLOR} payload="color" />
                        </div>

                    </div>
                    <div className='flex items-center justify-between gap-3'>
                        <h3 className='text-sm'>Back Number Outline Over</h3>
                        <div className='flex gap-3 items-center'>

                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3 max-w-[60px]'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Thikness</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.number.back?.outlineOver} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.BACK.OUTLINE_OVER, { outline: e.target.value });
                                }} />
                            </fieldset>

                            <ColorPicker color={item?.data.number.back?.outlineOverColor} actionKey={ACTIONS.EDITOR.JERSEY_NUMBER.BACK.OUTLINE_OVER_COLOR} payload="color" />
                        </div>

                    </div>
                    <div className='flex items-center justify-between gap-3'>

                        <h3 className='text-sm'>Back Number Shadow</h3>

                        <div className='flex gap-3 items-center'>

                            <label className="inline-flex items-center justify-between cursor-pointer w-full">
                                <input type="checkbox" value="" className="sr-only peer" checked={item?.data.number.back?.shadow} onChange={() => {
                                    updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.BACK.SHADOW, { shadow: !item?.data.number.back?.shadow });
                                }} />
                                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </label>

                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 max-w-[60px] mb-3'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Top</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.number.back?.shadowTop} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.BACK.SHADOW_TOP, { top: e.target.value });
                                }} />
                            </fieldset>
                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 max-w-[60px] mb-3'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Left</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.number.back?.shadowLeft} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.BACK.SHADOW_LEFT, { left: e.target.value });
                                }} />
                            </fieldset>

                            <ColorPicker color={item?.data.number.back?.shadowColor} actionKey={ACTIONS.EDITOR.JERSEY_NUMBER.BACK.SHADOW_COLOR} payload="color" />



                        </div>



                    </div>

                </form>
            )}
            {tab == 'sleeves' && (
                <form className='py-3'>


                    <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3'>
                        <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Sleevs Number Size</legend>
                        <Select
                            classNamePrefix="inside-select"
                            className='w-full h-full focus:outline-none '
                            value={item?.data.number?.sleeves?.size}
                            onChange={(value) => {
                                updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.SIZE, { size: value });
                            }}
                            options={sizes.sleeves}
                        />
                    </fieldset>


                    <div className='flex items-center justify-between gap-3'>
                        <h3 className='text-sm'>Sleeves Number Color</h3>
                        <ColorPicker color={item?.data.number?.sleeves?.color} actionKey={ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.COLOR} payload="color" />
                    </div>
                    <div className='flex items-center justify-between gap-3'>
                        <h3 className='text-sm'>Sleeves Number Outline</h3>
                        <div className='flex gap-3 items-center'>

                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3 max-w-[60px]'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Thikness</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.number.sleeves?.outline} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.OUTLINE, { outline: e.target.value });
                                }} />
                            </fieldset>

                            <ColorPicker color={item?.data.number.sleeves?.outlineColor} actionKey={ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.OUTLINE_COLOR} payload="color" />
                        </div>

                    </div>
                    <div className='flex items-center justify-between gap-3'>
                        <h3 className='text-sm'>Sleeves Number Outline Over</h3>
                        <div className='flex gap-3 items-center'>

                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 mb-3 max-w-[60px]'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Thikness</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.number.sleeves?.outlineOver} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.OUTLINE_OVER, { outline: e.target.value });
                                }} />
                            </fieldset>

                            <ColorPicker color={item?.data.number.sleeves?.outlineOverColor} actionKey={ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.OUTLINE_OVER_COLOR} payload="color" />
                        </div>

                    </div>
                    <div className='flex items-center justify-between gap-3'>

                        <h3 className='text-sm'>Sleeves Number Shadow</h3>

                        <div className='flex gap-3 items-center'>

                            <label className="inline-flex items-center justify-between cursor-pointer w-full">
                                <input type="checkbox" value="" className="sr-only peer" checked={item?.data.number.sleeves?.shadow} onChange={() => {
                                    updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.SHADOW, { shadow: !item?.data.number.sleeves?.shadow });
                                }} />
                                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </label>

                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 max-w-[60px] mb-3'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Top</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.number.sleeves?.shadowTop} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.SHADOW_TOP, { top: e.target.value });
                                }} />
                            </fieldset>
                            <fieldset className='rounded-md border border-gray-500 px-2 pt-0 max-w-[60px] mb-3'>
                                <legend className='px-2  top-0 text-xs mt-[-10px] italic'>Left</legend>
                                <input type="number" className='w-full h-full focus:outline-none text-sm pb-2' value={item?.data.number.sleeves?.shadowLeft} onChange={(e) => {
                                    updateDesign(ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.SHADOW_LEFT, { left: e.target.value });
                                }} />
                            </fieldset>

                            <ColorPicker color={item?.data.number.sleeves?.shadowColor} actionKey={ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.SHADOW_COLOR} payload="color" />



                        </div>



                    </div>




                </form>
            )}
        </div>

    );
};

export default AddJerseyNumber;