import React from 'react';

const Header: React.FC = () => {
    return (
        <header className='bg-gray-900 flex justify-center '>
            <h1 className='text-gray-50 text-2xl font-semibold'>
                <img src="logo.png" alt=""  className='h-[64px]'/>
            </h1>
        </header>
    );
};

export default Header;