import React, { useEffect } from 'react';
import Select from 'react-select';
import Icon from '../../../components/icons';
import PanelHeading from './panelHeading';
import { useDispatch } from 'react-redux';
import { ACTIONS } from '../../../_config';
import { useEditorDesignSelector } from '../../../store/selectors/editorSelector';

const PickDesign: React.FC = () => {
    const designTypes = [
        { value: 'jersey', label: 'Custom Football Jerseys' },
        { value: 'pants', label: 'Custom Football Pants' }
    ];
    const [selectedDesign, setSelectedDesign] = React.useState<any>(designTypes[0]);

    const item: any = useEditorDesignSelector();

    const [jerseys, setJerseys] = React.useState<any[] | null>(null);
    const [pants, setPants] = React.useState<any[] | null>(null);

    const dispatch = useDispatch();

    const selectItemForDesign = (item: any) => {
        dispatch({ type: ACTIONS.EDITOR.UPDATE_DESIGN, payload: { design: item } });
    }



    useEffect(() => {
        setJerseys([
            {
                id: 1,
                name: 'Jersey 1',
                thumb: 'https://riddell-gear-new.dreamztesting.com/items/jersey-1-thumb.png',
                data: {
                    colors: {
                        collar: '#ffffff',
                        sleevesBorder: '#ffffff',
                        sides: '#ffffff',
                        body: '#ffffff',
                        shoulder: '#ffffff',
                    },
                    number: {
                        number: '01',
                        font: null,
                        front: {
                            color: '#000000',
                            outlineColor: '#ffffff',
                            outline: 0,
                            outlineOverColor: '#ffffff',
                            outlineOver: 0,
                            shadow: false,
                            shadowColor: '#000000',
                            shadowTop: 0,
                            shadowLeft: 0,
                            size: null,
                        },
                        back: {
                            color: '#000000',
                            outlineColor: '#ffffff',
                            outline: 0,
                            outlineOverColor: '#ffffff',
                            outlineOver: 0,
                            shadow: false,
                            shadowColor: '#000000',
                            shadowTop: 0,
                            shadowLeft: 0,
                            size: null,
                        },
                        sleeves: {
                            color: '#000000',
                            outlineColor: '#ffffff',
                            outline: 0,
                            outlineOverColor: '#ffffff',
                            outlineOver: 0,
                            shadow: false,
                            shadowColor: '#000000',
                            shadowTop: 0,
                            shadowLeft: 0,
                            size: null,
                        },
                    },
                    text: {
                        front: {
                            text: '',
                            font: null,
                            color: 'none',
                            outlineColor: 'none',
                            outtine: 0,
                            outlineOverColor: '#ffffff',
                            outlineOver: 0,
                            shadow: false,
                            shadowColor: '#000000',
                            shadowTop: 0,
                            shadowLeft: 0,
                            size: null,
                        },
                        back: {
                            text: '',
                            font: null,
                            color: 'none',
                            outlineColor: 'none',
                            outtine: 0,
                            outlineOverColor: '#ffffff',
                            outlineOver: 0,
                            shadow: false,
                            shadowColor: '#000000',
                            shadowTop: 0,
                            shadowLeft: 0,
                            size: null,
                        },
                    }
                }
            }
        ]);
    }, []);


    return (
        <div>
            <PanelHeading title='Pick Design' />

            <Select
                className='mb-2 max-w-[320px]'
                value={selectedDesign}
                onChange={setSelectedDesign}
                options={designTypes}
            />

            {selectedDesign.value === 'jersey' && (
                <div>
                    {jerseys ? (
                        <div className='flex gap-3'>
                            {jerseys.map(jersey => (
                                <div key={jersey.id} onClick={() => { selectItemForDesign(jersey) }} className={`cursor-pointer border-2 p-2 shadow-md rounded-md ${item?.id == jersey.id ? 'border-green-700 shadow-slate-500' : 'border-slate-300'}`}>
                                    <img src={jersey.thumb} alt={jersey.name} className='w-[100px] h-[140px] object-contain' />
                                    <p className='text-center text-sm font-medium'>{jersey.name}</p>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <Icon name='loader' size={14} className='text-slate-900' />
                    )}
                </div>
            )}

            {selectedDesign.value === 'pants' && (
                <div>
                    {pants ? (
                        <ul>
                            {pants.map(pant => (
                                <li key={pant.id}>{pant.name}</li>
                            ))}
                        </ul>
                    ) : (
                        <Icon name='loader' size={14} className='text-slate-700' />
                    )}
                </div>
            )}
        </div>
    );
};

export default PickDesign;