import React, { HtmlHTMLAttributes } from 'react'
import { BsImage } from 'react-icons/bs';
import { SiRocket } from "react-icons/si";
import { FaBrush, FaDownload } from 'react-icons/fa6';
import { TbCircleDashedNumber7 } from "react-icons/tb";
import { IoIosSave } from "react-icons/io";
import { PiTextAaBold, PiTShirt } from "react-icons/pi";
import LoaderSVG from './iconSvgs/loader';


export type iconNames = 'jersey' | 'brush' | 'text' | 'number' | 'image' | 'rocket' | 'loader' | 'download' | 'save';

interface props extends HtmlHTMLAttributes<HTMLDivElement> {
    name: iconNames;
    size?: any;
    className?: string;
    onClick?: (e: any) => void
}

const Icon: React.FC<props> = ({
    children,
    name,
    size = '24',
    className = '',
    onClick,
    ...args
}) => {


    const getIconByName = (name: iconNames) => {
        switch (name) {
            case 'jersey': return <PiTShirt style={{ fontSize: `${size}px` }} />;
            case 'brush': return <FaBrush style={{ fontSize: `${size}px` }} />;
            case 'text': return <PiTextAaBold style={{ fontSize: `${size}px` }} />;
            case 'number': return <TbCircleDashedNumber7 style={{ fontSize: `${size}px` }} />;
            case 'image': return <BsImage style={{ fontSize: `${size}px` }} />;
            case 'rocket': return <SiRocket style={{ fontSize: `${size}px` }} />;
            case 'download': return <FaDownload style={{ fontSize: `${size}px` }} />;
            case 'save': return <IoIosSave style={{ fontSize: `${size}px` }} />;
            case 'loader': return <LoaderSVG size={size} />;

            default: return null;
        }
    }

    const getStyle = () => {
        let style: any = {}
        if (onClick) {
            style.cursor = 'pointer';
        }
        return style
    }

    return (
        <span onClick={onClick ? onClick : (e: any) => { }} className={`${className} ${(onClick) ? 'cursor-pointer' : ''}`}
            style={getStyle()}>
            {getIconByName(name)}
        </span>
    )
}

export default Icon;
