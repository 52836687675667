import React, { useEffect, useState } from 'react';
import PanelHeading from './panelHeading';
import { useColorsSelector, useEditorDesignSelector } from '../../../store/selectors/editorSelector';
import { useDispatch } from 'react-redux';
import { ACTIONS } from '../../../_config';
import toast from 'react-hot-toast';

const ChangeColor: React.FC = () => {
    const dispatch = useDispatch();
    const [color, setColor] = useState<any>(null);
    const colors = useColorsSelector();

    const item:any = useEditorDesignSelector();

    const [selectedPosition, setSelectedPosition] = useState<string | null>(null);

    const changeColor = (colorCode:string) => {
        if(selectedPosition == null){
            toast.error('Please select a colour position first');
            return;
        };  
        dispatch({ type: ACTIONS.EDITOR.UPDATE_DESIGN, payload: {design: {...item,...{
            data: {
                ...item.data,
                colors: {
                    ...item.data.colors,
                    [selectedPosition as string]: colorCode
                }
            }
        }}} });
    };


    useEffect(() => {
        if(item){
            console.log('------------------>',item);
        } 
    }, [item]);

    return (
        <div>
            <PanelHeading title='Pick Color'/>

            <div className='flex gap-3 flex-wrap mt-6'>
                {item && Object.keys(item.data.colors).map((colorName:any) => (
                    <div key={`color-${colorName}`} onClick={()=>{
                        setSelectedPosition(colorName);
                    }} className={`flex flex-col items-center justify-center border-2 border-gray-400 rounded-md p-3 shadow cursor-pointer ${selectedPosition == colorName?'border-green-600 shadow-gray-600':'border-gray-400 '}`}>
                        <div className='w-6 h-6 rounded-md shadow-gray-900 shadow-sm ' style={{ backgroundColor: item.data.colors[colorName] }}></div>
                        <p className='text-xs pt-1'>{colorName}</p>
                    </div>
                ))}
            </div>
            <div className='flex gap-3 flex-wrap mt-6'>
                {colors && colors.map((color) => (
                    <div key={color.name} className=''>
                        <div onClick={()=>{
                            changeColor(color.hex);
                        }} className='w-6 h-6 rounded-md shadow-gray-900 shadow-sm cursor-pointer' style={{ backgroundColor: color.hex }}></div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ChangeColor;