import React from 'react';
import { Link } from 'react-router-dom';
import { URLS } from '../_config';

const Home: React.FC = () => {
    return (
        <div className='p-3 bg-stone-100'>
            <h1>Welcome to the Home Page</h1>
            <p>This is the home page of our application.</p>

            <Link to={URLS.DESIGN} className='btn btn-primary'>Go to the Design Page</Link>
        </div>
    );
};

export default Home;