import { ACTIONS, KEYS } from '../../_config'
import { ActionExtended, numberSizes,  textSizes } from '../../_config/interfaces/interfaces';
type editorPanel = 'pick-design' | 'change-color' | 'add-text' | 'jersey-number' | 'add-image' | 'ready-to-go';

export interface EditorReducer {
	editorPanel: editorPanel;
	colors: any[];
	textSizes: textSizes;
	numberSizes: numberSizes;
	textPositions: textSizes;
	numberPositions: textSizes;
	design: any;
	fonts:any[];
}

const initialState: EditorReducer = {
	editorPanel: 'pick-design',
	colors: [
        { name: 'Black', hex: '#000000' },
        { name: 'Tx Orange', hex: '#ae3f20' },
        { name: 'Vegas Gold', hex: '#e4c79c' },
        { name: 'Orange', hex: '#ee3124' },
        { name: 'Columbia', hex: '#60a7e5' },
        { name: 'Maroon', hex: '#491e30' },
        { name: 'Cardinal', hex: '#742230' },
        { name: 'Scarlet', hex: '#c7162c' },
        { name: 'Kelly', hex: '#007144' },
        { name: 'Dark Green', hex: '#003333' },
        { name: 'Purple', hex: '#320055' },
        { name: 'Royal', hex: '#13138c' },
        { name: 'Navy', hex: '#010122' },
        { name: 'Light Gold', hex: '#ffb900' },
        { name: 'Silver', hex: '#c3c4cc' },
        { name: 'Charcoal', hex: '#656571' },
        { name: 'White', hex: '#ffffff' },
    ],
	textSizes: {
		front: [
		
			{label: '2 inch', value: 2},
			{label: '3 inch', value: 3},
			{label: '4 inch', value: 4}
		],
		back:[
		
			{label: '2 inch', value: 2},
			{label: '3 inch', value: 3},
			{label: '4 inch', value: 4}
		]
	},
	numberSizes: {
		front: [
			{label: '2 inch', value: 2},
			{label: '3 inch', value: 3},
			{label: '4 inch', value: 4},
			{label: '6 inch', value: 6},
			{label: '8 inch', value: 8},
			{label: '10 inch', value: 10},
			{label: '12 inch', value: 12}
		],
		back:[
			{label: '2 inch', value: 2},
			{label: '3 inch', value: 3},
			{label: '4 inch', value: 4},
			{label: '6 inch', value: 6},
			{label: '8 inch', value: 8},
			{label: '10 inch', value: 10},
			{label: '12 inch', value: 12}
		],
		sleeves:[
			{label: '1 inch', value: 1},
			{label: '2 inch', value: 2},
			{label: '3 inch', value: 3}
		]
	},
	textPositions: {
		front: [
			{label: 'Top', value: 'top'},
			{label: 'Middle', value: 'middle'},
			{label: 'Bottom', value: 'bottom'}
		],
		back: [
			{label: 'Top', value: 'top'},
			{label: 'Middle', value: 'middle'},
			{label: 'Bottom', value: 'bottom'}
		],
	},
	numberPositions: {
		front: [
			{label: 'Top', value: 'top'},
			{label: 'Center', value: 'center'},
		],
		back: [
			{label: 'Top', value: 'top'},
			{label: 'Center', value: 'center'},
		],
	},
	fonts: [
		{label: 'Times Bold', value: 'font-times-shadow', fontfamily: 'Shadow'},
		{label: 'Super Block', value: 'font-times-russoone', fontfamily: 'RussoOne'},
		{label: 'Drop Shadow Letters', value: 'font-times-shadow', fontfamily: 'Arial'},
		{label: 'Gap Outline Letters', value: 'font-times-shadow', fontfamily: 'Arial'},
		{label: 'Shadow Block', value: 'font-times-shadow', fontfamily: 'Arial'},
		{label: 'Blade Letters', value: 'font-times-shadow', fontfamily: 'Arial'},
		{label: 'City Block Letters', value: 'font-times-shadow', fontfamily: 'Arial'},
		{label: '3D Letters', value: 'font-times-shadow', fontfamily: 'Arial'},
		{label: 'Agency', value: 'font-times-shadow', fontfamily: 'Arial'},
	],
	design: null
};

const editorReducer = (state = initialState, action: ActionExtended) => {
	switch (action.type) {
		case ACTIONS.EDITOR.CHANGE_EDIT_PANEL:
			return {
				...state,
				editorPanel: action.payload.editorPanel,
			};
		case ACTIONS.EDITOR.COLORS:
			return {
				...state,
				colors: action.payload.colors,
			};

		case ACTIONS.EDITOR.UPDATE_DESIGN:
			return {
				...state,
				design: action.payload.design,
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.FONT:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, font:action.payload.font}}},
			};

		case ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, front: {...state.design.data.number.front, color: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.OUTLINE_COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, front: {...state.design.data.number.front, outlineColor: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.OUTLINE:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number,front: {...state.design.data.number.front, outline: action.payload.outline}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.OUTLINE_OVER_COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, front: {...state.design.data.number.front, outlineOverColor: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.OUTLINE_OVER:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, front: {...state.design.data.number.front, outlineOver: action.payload.outline}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.SHADOW:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, front: {...state.design.data.number.front, shadow: action.payload.shadow}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.SHADOW_COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, front: {...state.design.data.number.front, shadowColor: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.SHADOW_TOP:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, front: {...state.design.data.number.front, shadowTop: action.payload.top}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.SHADOW_LEFT:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, front: {...state.design.data.number.front, shadowLeft: action.payload.left}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.FRONT.SIZE:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, front: {...state.design.data.number.front, size: action.payload.size}}}},
			};



		case ACTIONS.EDITOR.JERSEY_NUMBER.BACK.COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, back: {...state.design.data.number.back, color: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.BACK.OUTLINE_COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, back: {...state.design.data.number.back, outlineColor: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.BACK.OUTLINE:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, back: {...state.design.data.number.back, outline: action.payload.outline}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.BACK.OUTLINE_OVER_COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, back: {...state.design.data.number.back, outlineOverColor: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.BACK.OUTLINE_OVER:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, back: {...state.design.data.number.back, outlineOver: action.payload.outline}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.BACK.SHADOW:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, back: {...state.design.data.number.back, shadow: action.payload.shadow}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.BACK.SHADOW_COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, back: {...state.design.data.number.back, shadowColor: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.BACK.SHADOW_TOP:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, back: {...state.design.data.number.back, shadowTop: action.payload.top}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.BACK.SHADOW_LEFT:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, back: {...state.design.data.number.back, shadowLeft: action.payload.left}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.BACK.SIZE:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, back: {...state.design.data.number.back, size: action.payload.size}}}},
			};



		case ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, sleeves: {...state.design.data.number.sleeves, color: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.OUTLINE_COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, sleeves: {...state.design.data.number.sleeves, outlineColor: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.OUTLINE:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, sleeves: {...state.design.data.number.sleeves, outline: action.payload.outline}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.OUTLINE_OVER_COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, sleeves: {...state.design.data.number.sleeves, outlineOverColor: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.OUTLINE_OVER:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, sleeves: {...state.design.data.number.sleeves, outlineOver: action.payload.outline}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.SHADOW:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, sleeves: {...state.design.data.number.sleeves, shadow: action.payload.shadow}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.SHADOW_COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, sleeves: {...state.design.data.number.sleeves, shadowColor: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.SHADOW_TOP:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, sleeves: {...state.design.data.number.sleeves, shadowTop: action.payload.top}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.SHADOW_LEFT:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, sleeves: {...state.design.data.number.sleeves, shadowLeft: action.payload.left}}}},
			};
		case ACTIONS.EDITOR.JERSEY_NUMBER.SLEEVES.SIZE:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, number:{...state.design.data.number, sleeves: {...state.design.data.number.sleeves, size: action.payload.size}}}},
			};
		
		
		
		case ACTIONS.EDITOR.TEXT.FRONT.TEXT:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, front: {...state.design.data.text.front, text: action.payload.text}}}},
			};
		case ACTIONS.EDITOR.TEXT.FRONT.FONT:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, front: {...state.design.data.text.front, font: action.payload.font}}}},
			};
		case ACTIONS.EDITOR.TEXT.FRONT.COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, front: {...state.design.data.text.front, color: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.TEXT.FRONT.OUTLINE_COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, front: {...state.design.data.text.front, outlineColor: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.TEXT.FRONT.OUTLINE:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, front: {...state.design.data.text.front, outline: action.payload.outline}}}},
			};
		case ACTIONS.EDITOR.TEXT.FRONT.OUTLINE_OVER_COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, front: {...state.design.data.text.front, outlineOverColor: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.TEXT.FRONT.OUTLINE_OVER:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, front: {...state.design.data.text.front, outlineOver: action.payload.outline}}}},
			};
		case ACTIONS.EDITOR.TEXT.FRONT.SHADOW:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, front: {...state.design.data.text.front, shadow: action.payload.shadow}}}},
			};
		case ACTIONS.EDITOR.TEXT.FRONT.SHADOW_COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, front: {...state.design.data.text.front, shadowColor: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.TEXT.FRONT.SHADOW_TOP:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, front: {...state.design.data.text.front, shadowTop: action.payload.top}}}},
			};
		case ACTIONS.EDITOR.TEXT.FRONT.SHADOW_LEFT:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, front: {...state.design.data.text.front, shadowLeft: action.payload.left}}}},
			};
		case ACTIONS.EDITOR.TEXT.FRONT.SIZE:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, front: {...state.design.data.text.front, size: action.payload.size}}}},
			};
		case ACTIONS.EDITOR.TEXT.BACK.TEXT:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, back: {...state.design.data.text.back, text: action.payload.text}}}},
			};
		case ACTIONS.EDITOR.TEXT.BACK.FONT:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, back: {...state.design.data.text.back, font: action.payload.font}}}},
			};
		case ACTIONS.EDITOR.TEXT.BACK.COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, back: {...state.design.data.text.back, color: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.TEXT.BACK.OUTLINE_COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, back: {...state.design.data.text.back, outlineColor: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.TEXT.BACK.OUTLINE:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, back: {...state.design.data.text.back, outline: action.payload.outline}}}},
			};
		case ACTIONS.EDITOR.TEXT.BACK.OUTLINE_OVER_COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, back: {...state.design.data.text.back, outlineOverColor: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.TEXT.BACK.OUTLINE_OVER:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, back: {...state.design.data.text.back, outlineOver: action.payload.outline}}}},
			};
		case ACTIONS.EDITOR.TEXT.BACK.SHADOW:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, back: {...state.design.data.text.back, shadow: action.payload.shadow}}}},
			};
		case ACTIONS.EDITOR.TEXT.BACK.SHADOW_COLOR:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, back: {...state.design.data.text.back, shadowColor: action.payload.color}}}},
			};
		case ACTIONS.EDITOR.TEXT.BACK.SHADOW_TOP:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, back: {...state.design.data.text.back, shadowTop: action.payload.top}}}},
			};
		case ACTIONS.EDITOR.TEXT.BACK.SHADOW_LEFT:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, back: {...state.design.data.text.back, shadowLeft: action.payload.left}}}},
			};
		case ACTIONS.EDITOR.TEXT.BACK.SIZE:
			return {
				...state,
				design:{...state.design, data:{...state.design.data, text:{...state.design.data.text, back: {...state.design.data.text.back, size: action.payload.size}}}},
			};
		default:
			return state;
	}
};

export default editorReducer;
