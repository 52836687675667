import React from 'react';
import Sidebar from './sidebar';
import { useDispatch } from 'react-redux';
import { useEditorPanelSelector } from '../../store/selectors/editorSelector';
import { KEYS } from '../../_config';
import PickDesign from './components/pickDesign';
import ChangeColor from './components/changeColor';
import AddText from './components/addText';
import AddJerseyNumber from './components/addJerseyNumber';
import AddImage from './components/addImage';
import ReadyToGo from './components/readyToGo';

interface EditorProps {
   
}

const Editor: React.FC<EditorProps> = ({}) => {
    const dispach = useDispatch();
    const panel = useEditorPanelSelector();
    return (
        <div className='flex w-full max-w-[512px] min-w-[400px] flex-shrink-0'>
            <Sidebar />
            <div className='flex-grow p-3'>
                {panel == KEYS.EDIT_PANELS.PICK_DESIGN && <PickDesign />}
                {panel == KEYS.EDIT_PANELS.CHANGE_COLOR && <ChangeColor />}
                {panel == KEYS.EDIT_PANELS.ADD_TEXT && <AddText />}
                {panel == KEYS.EDIT_PANELS.JERSEY_NUMBER && <AddJerseyNumber />}
                {panel == KEYS.EDIT_PANELS.ADD_IMAGE && <AddImage  />}
                {panel == KEYS.EDIT_PANELS.READY_TO_GO && <ReadyToGo />}
            </div>
        </div>
    );
};

export default Editor;