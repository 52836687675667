import { Outlet, Navigate } from 'react-router-dom';
import { URLS } from '.';
import { useAuthStatus } from '../store/selectors/userSelector';

const PrivateRoutes = () => {
    const isAuth = useAuthStatus();
    return (
        isAuth ? <Outlet /> : <Navigate to={URLS.LOGIN} />
    )
}

export default PrivateRoutes;