


import createSagaMiddleware from 'redux-saga'
import { applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducers'
import { sagas } from './sagas'
import { configureStore } from '@reduxjs/toolkit'



const sagaMiddleware = createSagaMiddleware()

const middlewares:any = []
middlewares.push(sagaMiddleware)
let middleware:any = applyMiddleware(...middlewares)

if (['dev'].indexOf('dev') >= 0) {
  if ((window as any).devToolsExtension) {
    middleware = compose(middleware, (window as any).devToolsExtension())
  }
  if ((window as any).__REDUX_DEVTOOLS_EXTENSION__) {
    middleware = compose(
      middleware,
      (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
    )
  }
}



const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['editor'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  //middleware: (getDefaultMiddleware)=>{return middlewares},
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck:false}).concat(sagaMiddleware),
})

const persistor = persistStore(store)

sagaMiddleware.run(sagas)

export { store, persistor }